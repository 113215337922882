<template>
  <v-card
    rounded="lg"
    flat
    class="pa-4 message-component-height"
    :key="componentKey"
  >
    <v-card-title
      class="text-color-title font-weight-bold py-1 mb-2 message-conversation-title"
    >
      {{ conversationTitle }}
      <v-spacer></v-spacer>
      <v-btn
        depressed
        fab
        small
        color="transparent"
        :loading="refreshLoading"
        @click="refreshMessages()"
        :disabled="disableForm"
      >
        <v-icon>mdi-restart</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <div
      ref="conversationList"
      @scroll="scrollMessages()"
      class="mt-2 px-2 message-conversation-list overflow-y-auto"
    >
      <div
        class="fill-height d-flex justify-center align-center"
        v-if="loading"
      >
        <global-loading size="sm"></global-loading>
      </div>
      <template v-if="!loading && messages.length > 0">
        <div v-for="(message, key) in messages" :key="key" class="body-2 my-2">
          <div
            class="d-flex"
            :class="message.me ? 'justify-end' : 'justify-start'"
          >
            <div
              class="rounded-lg message-bubble-width pa-4 mb-2"
              :class="message.class"
            >
              {{ message.message }}
            </div>
          </div>
          <div :class="`${message.me ? 'text-right' : 'text-left'} caption`">
            <span v-if="!message.me">
              <span>{{
                formatName(message.user.first_name, message.user.last_name)
              }}</span>
              · {{ message.timestamp | messageTimestamp }}
            </span>
            <span v-else>
              <span>{{ message.timestamp | messageTimestamp }}</span>
            </span>
            <!-- <span v-if="message.me">{{ message | formatName2 }}</span> · {{ message.timestamp | messageTimestamp }} -->
          </div>
        </div>
      </template>
    </div>
    <v-card-actions class="message-conversation-writer">
      <div class="w-100">
        <div class="d-flex align-start pt-4">
          <v-text-field
            v-model="message"
            filled
            rounded
            dense
            class="pr-4 rounded-sm"
            hide-details
            height="0"
            :disabled="sendLoading || disableForm"
            @keyup.enter="sendMessage"
            autofocus
          ></v-text-field>
          <v-btn
            color="cyan lighten-2 py-4"
            class="text-color-white"
            depressed
            @click="sendMessage()"
            :disabled="disableForm || sendLoading"
            :loading="sendLoading"
          >
            Send
          </v-btn>
        </div>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from "moment";
import { CHAT_SUPPORT_EVENT_BUS } from "../js/ChatSupportEventBus";
import { RepositoryFactory } from "@/services/repositories/repositoryFactory";
import Storage from "@/services/storage.js";
import GlobalLoading from "@/components/miscellaneous/loadings/GlobalLoading";
// import {
//   SET_CONNECT_GROUP_CONVERSATION_LAST_MESSAGE,
//   SET_DREAM_TEAM_CONVERSATION_LAST_MESSAGE,
//   SET_PASTOR_CONVERSATION_LAST_MESSAGE
// } from "@/store/modules/chat.module";

let storage = new Storage();

const messagesRepository = RepositoryFactory.get("message");

export default {
  components: { GlobalLoading },

  filters: {
    messageTimestamp(value) {
      if (value) {
        return moment(value).format("hh:mm A, MMM DD");
      }

      return null;
    },
    formatName2(value) {
      console.log({ value });
    }
  },

  data: () => ({
    message: null,
    conversationTitle: null,
    messages: [],
    valid: false,
    previousScrollPosition: 0,
    disableForm: true,
    filters: {
      page: 1
    },
    user: {},
    repositoryKey: null,
    threadId: null,
    sendLoading: false,
    lastPage: 1,
    componentKey: 0,
    refreshLoading: false,
    loading: false,
    pusher: {
      channel: null
    }
  }),

  methods: {
    formatName(firstName, lastName) {
      let name = "";
      if (!lastName) name = firstName;
      if (firstName && lastName) name = `${firstName} ${lastName}`;
      return name;
    },

    getConversationsViaRepository() {
      let haveRepositoryAndThreadId = this.repositoryKey && this.threadId;
      let haveReachedLastPage = this.filters.page <= this.lastPage;
      if (haveRepositoryAndThreadId && haveReachedLastPage) {
        let params = {
          ...this.filters
        };
        if (this.filters.page === 1) this.loading = true;
        messagesRepository
          .getConversationsViaRepository(this.repositoryKey, this.threadId, {
            params
          })
          .then(response => {
            let data = response.data.responseData;
            this.lastPage = data.last_page;
            let messages = data.data.map(item => this.toMessageObject(item));
            this.messages.unshift(...messages.reverse());
            this.loading = false;
            if (this.filters.page === 1) {
              this.setScrollBottom();
            }
            if (data.current_page <= data.last_page) {
              this.filters.page++;
            }
          })
          .catch(error => {
            this.loading = false;
            console.log({ error });
          });
      }
    },

    sendMessage() {
      const valid = !!this.message;
      if (valid) {
        this.sendLoading = true;
        let form = {
          message: this.message
        };
        this.sendLoading = false;
        this.message = null;
        messagesRepository
          .sendMessageViaRepository(this.repositoryKey, this.threadId, form)
          .then(() => {
            this.$nextTick(() => {
              this.setScrollBottom();
            });
          })
          .catch(error => {
            this.sendLoading = false;
            console.log({ error });
          });
      }
    },

    toMessageObject(message) {
      const me =
        message.user_id === this.user.id ||
        (message.user ? message.user.user_type === "admin" : false) ||
        (message.user ? message.user.user_type === "superadmin" : false);
      return {
        id: message.id,
        me,
        message: message.message,
        class: me
          ? "bg-secondary text-color-white"
          : "main-bg text-color-black",
        timestamp: message.created_at,
        user: Object.assign({}, message.user)
      };
    },

    setScrollBottom() {
      this.$nextTick(() => {
        const { conversationList } = this.$refs;
        const scrollHeight = conversationList.scrollHeight || 0;
        const clientHeight = conversationList.clientHeight || 0;
        conversationList.scrollTop = scrollHeight || clientHeight;
      });
    },

    scrollMessages() {
      let scrollPosition = this.$refs.conversationList;
      let messageListHeight = 420; //420px see message-conversation-list class

      if (
        scrollPosition.scrollTop === 0 &&
        scrollPosition.scrollHeight > messageListHeight
      ) {
        this.previousScrollPosition = scrollPosition.scrollHeight;

        this.getConversationsViaRepository();
      }
    },

    scrollUnshift() {
      let scrollPosition = this.$refs.conversationList;
      scrollPosition.scrollTop =
        scrollPosition.scrollHeight - this.previousScrollPosition;
    },

    async seeConversations() {
      await this.getConversationsViaRepository();

      setTimeout(() => {
        this.setScrollBottom();
      }, 500);
    },

    showContactConversationsEventBus() {
      CHAT_SUPPORT_EVENT_BUS.$off("showContactConversations");
      CHAT_SUPPORT_EVENT_BUS.$on("showContactConversations", async data => {
        await this.returnToDefault();
        this.disableForm = false;
        this.conversationTitle = data.contact.name;
        this.repositoryKey = data.repositoryKey;
        this.threadId = data.contact.id;
        await this.seeConversations();
      });
    },

    clearMessagesEventBus() {
      CHAT_SUPPORT_EVENT_BUS.$off("clearMessages");
      CHAT_SUPPORT_EVENT_BUS.$on("clearMessages", () => {
        this.componentKey++;
        this.returnToDefault();
      });
    },

    resetMessageThreadEventBus() {
      CHAT_SUPPORT_EVENT_BUS.$off("resetMessageThread");
      CHAT_SUPPORT_EVENT_BUS.$on("resetMessageThread", () => {
        this.componentKey++;
        this.returnToDefault();
      });
    },

    setConversationChannelEventBus() {
      CHAT_SUPPORT_EVENT_BUS.$off("setCurrentConversationChannel");
      CHAT_SUPPORT_EVENT_BUS.$on(
        "setCurrentConversationChannel",
        channelName => {
          console.log(channelName);
          const processMessage = message => {
            if (message.conversation_id === this.threadId) {
              this.addMessage(message);
            }
          };
          this.$pusher.connection.bind("message", ({ channel, data }) => {
            if (channel === channelName) {
              processMessage(data.message);
            }
          });
          // if (channel) {
          //   this.pusher.channel = channel;
          //   channel.bind(
          //     "App\\Events\\DreamTeamChatMessageCreated",
          //     ({ message }) => {
          //       processMessage(message);
          //     }
          //   );
          //   channel.bind(
          //     "App\\Events\\ConnectGroupChatMessageCreated",
          //     ({ message }) => {
          //       processMessage(message);
          //     }
          //   );
          //   channel.bind(
          //     "App\\Events\\PastorChatMessageCreated",
          //     ({ message }) => {
          //       processMessage(message);
          //     }
          //   );
          //   const processMessage = message => {
          //     if (message.conversation_id === this.threadId) {
          //       this.addMessage(message);
          //     }
          //   };
          // }
        }
      );
    },

    addMessage(message) {
      const alreadyAdded = !!this.messages
        .map(item => item.id)
        .find(id => id === message.id);
      if (!alreadyAdded) {
        this.messages.push(this.toMessageObject(message));
        this.setScrollBottom();
        let event = {
          threadId: this.threadId,
          message: message.message
        };
        CHAT_SUPPORT_EVENT_BUS.$emit("sendContactToTop", event);
      }
    },

    setupEventBus() {
      this.clearMessagesEventBus();
      this.showContactConversationsEventBus();
      this.resetMessageThreadEventBus();
      this.setConversationChannelEventBus();
    },

    getUser() {
      this.user = storage.get("user");
    },

    returnToDefault() {
      this.lastPage = 1;
      this.conversationTitle = null;
      this.messages = [];
      this.filters = {
        page: 1
      };
      this.previousScrollPosition = 0;
      this.threadId = null;
      this.disableForm = true;
      this.pusher.channel = null;
    },

    async refreshMessages() {
      this.componentKey++;

      this.refreshLoading = true;

      this.lastPage = 1;
      this.messages = [];
      this.filters = {
        page: 1
      };
      this.previousScrollPosition = 0;
      this.disableForm = true;

      await this.seeConversations();
      this.refreshLoading = false;
    }
  },

  mounted() {
    this.setupEventBus();
    this.getUser();
  }
};
</script>

<style lang="scss" scoped>
.message-conversation-title {
  height: 8%;
}

.message-conversation-list {
  //height: 420px;
  /* Using plain CSS */
  @media (min-height: 500px) {
    height: 75%;
  }

  @media (min-height: 700px) and (max-height: 839px) {
    height: 78%;
  }

  @media (min-height: 840px) {
    height: 80%;
  }
}

.message-conversation-writer {
  height: 12%;
}

.message-bubble {
  &-width {
    max-width: 400px;
  }
}
</style>
