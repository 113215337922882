<template>
  <v-progress-circular
    :size="sizeLocal.value"
    :width="sizeLocal.width"
    color="black"
    indeterminate
  >
    <img :src="logo" :height="sizeLocal.height" alt="Logo" />
  </v-progress-circular>
</template>

<script>
export default {
  props: {
    size: [String, null]
  },

  data: () => ({
    logo: require("../../../assets/logo/inspire_church_logo_bw.png")
  }),

  computed: {
    sizeLocal() {
      let size = { value: 70, height: 70, width: 7 };
      if (this.size === "sm") {
        size = Object.assign({}, { value: 50, height: 50, width: 5 });
      }

      return size;
    }
  }
};
</script>
